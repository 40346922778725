import React, { useState } from 'react';
import { Dialog, IconButton, Button, Flex, Text, TextField } from '@radix-ui/themes';
import { Cross1Icon } from '@radix-ui/react-icons';
import { ConfirmDialog } from 'components/dialog';
import './style.scss';
import { useFormik } from 'formik';
import { checkPassword, editPassword, logoutUser } from 'store/slices/users';
import { validationSchemaPassword } from 'util/validationSchemas';
import { useDispatch } from 'react-redux';

export function PasswordChangeDialog({ setIsOpenChangePasswordDialog }) {
  const dispatch = useDispatch();

  const [errorInfo, setErrorInfo] = useState({
    error: false,
    message: ''
  });
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false); // Success 팝업 상태 추가

  const formik = useFormik({
    initialValues: {
      prevPassword: '',
      newPassword: '',
      newPasswordConfirm: ''
    },
    validationSchema: validationSchemaPassword,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: async () => {
      if (errorInfo.error) return;
      setIsOpenDialog(true);
    }
  });

  const handleBlur = async () => {
    const { data } = await checkPassword({ prevPassword: formik.values.prevPassword });

    if (!data.data) {
      setErrorInfo({ error: true, message: '기존 비밀번호를 확인해주세요' });
    } else {
      setErrorInfo({ error: false, message: '' });
    }
  };

  const handleConfirm = async () => {
    const { prevPassword, newPassword } = formik.values;
    try {
      const { data } = await editPassword({ prevPassword, newPassword });

      if (data.status === 201) {
        setIsOpenDialog(false);
        setIsSuccessPopupOpen(true);
      }
    } catch (error) {
      console.error('Unexpected error: ', error);
    }
  };

  const closePasswordDialog = async () => {
    setIsOpenChangePasswordDialog(false);
    await dispatch(logoutUser());
  };

  return (
    <>
      <Dialog.Content maxWidth="520px" className="basic-dialog">
        <Dialog.Close>
          <IconButton className="rt-variant-text close-btn">
            <Cross1Icon />
          </IconButton>
        </Dialog.Close>
        <Dialog.Title>비밀번호 변경</Dialog.Title>
        <form onSubmit={formik.handleSubmit}>
          <Flex align="center" mt="var(--space-7)" mb="var(--space-2)">
            <Text align="left" className="form-label" htmlFor="prevPassword">
              기존 비밀번호 입력
            </Text>
            <TextField.Root
              required
              size="3"
              type="text"
              id="prevPassword"
              name="prevPassword"
              value={formik.values.prevPassword || ''}
              onChange={formik.handleChange}
              onBlur={e => {
                formik.handleBlur(e);
                handleBlur();
              }}
            />
          </Flex>
          {errorInfo.error && (
            <Text as="p" mt="var(--space-1)" mb="var(--space-2)" className="error-text">
              {errorInfo.message}
            </Text>
          )}
          <Flex align="center" mb="var(--space-2)">
            <Text align="left" className="form-label" htmlFor="newPassword">
              새로운 비밀번호 입력
            </Text>
            <TextField.Root
              required
              size="3"
              type="text"
              id="newPassword"
              name="newPassword"
              placeholder="영문, 숫자 조합 6자 이상"
              value={formik.values.newPassword || ''}
              onBlur={formik.handleBlur}
              onChange={e => formik.setFieldValue('newPassword', e.target.value)}
            />
          </Flex>
          {formik.touched.newPassword && formik.errors.newPassword && (
            <Text as="p" mt="var(--space-1)" mb="var(--space-2)" className="error-text">
              {formik.errors.newPassword}
            </Text>
          )}
          <Flex align="center" mb="var(--space-3)">
            <Text align="left" className="form-label" htmlFor="newPasswordConfirm">
              새로운 비밀번호 확인
            </Text>
            <TextField.Root
              required
              size="3"
              type="text"
              id="newPasswordConfirm"
              name="newPasswordConfirm"
              placeholder="영문, 숫자 조합 6자 이상"
              value={formik.values.newPasswordConfirm || ''}
              onChange={e => formik.setFieldValue('newPasswordConfirm', e.target.value)}
              onBlur={formik.handleBlur}
            />
          </Flex>
          {formik.touched.newPasswordConfirm && formik.errors.newPasswordConfirm && (
            <Text as="p" mt="var(--space-1)" mb="var(--space-2)" className="error-text">
              {formik.errors.newPasswordConfirm}
            </Text>
          )}

          <Flex gap="var(--space-2)" mt={errorInfo.error ? 'var(--space-3)' : 'var(--space-7)'} justify="center">
            <Button size="2" className="btn-lg" type="submit">
              확인
            </Button>
            <Dialog.Close>
              <Button size="2" variant="outline">
                취소
              </Button>
            </Dialog.Close>
          </Flex>
        </form>
      </Dialog.Content>
      <Dialog.Root open={isOpenDialog} onOpenChange={setIsOpenDialog}>
        <ConfirmDialog title="비밀번호 변경 확인" description="비밀번호를 변경하시겠습니까?" onConfirm={handleConfirm} />
      </Dialog.Root>
      <Dialog.Root open={isSuccessPopupOpen} onOpenChange={setIsSuccessPopupOpen}>
        <ConfirmDialog
          description="비밀번호가 변경되었습니다"
          description2="로그인 페이지로 이동합니다"
          showCancel={false}
          onConfirm={closePasswordDialog}
        />
      </Dialog.Root>
    </>
  );
}
